<template>
  <div
    class="login full-width row wrap justify-center items-center content-center"
    style="min-height: calc(100vh - 50px)"
  >
    <q-card class="row col-xs-8 col-sm-5 col-md-3">
      <q-card-section
        class="full-width row wrap justify-center items-center content-center"
      >
        <q-avatar rounded size="150px" class="">
          <img src="img/EscudoUNAMwhite.svg" />
        </q-avatar>
      </q-card-section>
      <q-card-section
        class="full-width row wrap justify-center items-center content-center"
      >
        <span class="text-center text-h5">SIDST</span>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-actions
        class="full-width row wrap justify-center items-center content-center"
      >
        <q-btn
          class="full-width text-capitalize"
          color="primary"
          @click="handleAuthClick"
        >
          Acceder
        </q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
// import { watch } from "vue";
import { router } from "@/router";
import { handleAuthClick, isSignedIn } from "@/api/gapi/gapi";

export default {
  name: "LogIn",
  components: {
    //  VinculacionExterna: defineAsyncComponent(() =>
    //    import("@/components/VinculacionExterna.vue")
    //  ),
    // VinculacionExterna,
  },
  setup() {
    console.log("LogIn ready");
    // handleClientLoad();
    // onMounted(() => {});
    return {
      handleAuthClick,
    };
  },
  beforeRouteEnter() {
    if (isSignedIn.value) {
      router.push("/indicadores");
    }
  },
};
</script>
<style lang="css" scoped></style>
